import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const frontmatter = {
  title: 'Eliminate Boilerplate with React Native Templates',
  date: '2017-04-17T22:58:59.501Z',
  draft: false
};
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  frontmatter,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`At `}<a parentName="p" {...{
        "href": "https://codekoalas.com"
      }}>{`Code Koalas`}</a>{`, we love React Native. We also `}<em parentName="p">{`hate`}</em>{` boilerplate and unfortunately it seems like all of our new React Native projects use the same libraries and the same initial boilerplate. We decided to eliminate that boilerplate with React Native CLI's new unspoken feature: project templates. With this feature, you too can eliminate boilerplate and speed along the production of your new shiny React Native apps.`}</p>
    <h2>{`How to use React Native project templates`}</h2>
    <p>{`If you've not heard of them before, `}<a parentName="p" {...{
        "href": "https://github.com/facebook/react-native/releases/tag/v0.42.3"
      }}>{`React 0.42`}</a>{` quietly released a feature that allows a user to specify a template for a newly initialized React Native app and even bundled a template that demonstrated how to build an app with navigation. Even cooler, you can also specify npm packages to create the new app from like so:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`react-native init newApp --template koality`}</code></pre></div>
    <p>{`And I'm going to show you how to build your own.`}</p>
    <h2>{`Initialize`}</h2>
    <p>{`Unfortunately, the tooling for creating a template compatible with React Native's cli is nonexistent. The solution we're going to use here is to leverage the existing architecture around creating a React Native app and change the end product into a template. Let's start by initializing our React Native project.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`react-native init <your sample app template name here>`}</code></pre></div>
    <h2>{`Code`}</h2>
    <p>{`Here's where I leave it up to your imagination. In the case of our team, we created a `}<a parentName="p" {...{
        "href": "https://github.com/CodeKoalas/koality-react-native-template"
      }}>{`super basic app template`}</a>{` that integrated:`}</p>
    <ul>
      <li parentName="ul">{`React Router`}</li>
      <li parentName="ul">{`Redux`}</li>
      <li parentName="ul">{`Redux persist`}</li>
      <li parentName="ul">{`Styled Components`}</li>
    </ul>
    <p>{`and other basic bits of boilerplate necessary to start most of our internal projects. I recommend keeping this template fairly minimal so that you don't spend time replacing libraries or ripping out parts you don't need and therefore wasting more time than you end up saving.`}</p>
    <h2>{`Clean`}</h2>
    <p>{`After you're done coding and the app looks about like you would want, it's time to actually turn it into a React Native template. Start by modifying your package.json which probably looks like this by now.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`{
  "name": "sample",
  "version": "0.0.1",
  "private": true,
  "scripts": {
    "start": "node node_modules/react-native/local-cli/cli.js start",
    "test": "jest"
  },
  "dependencies": {
    "react": "16.0.0-alpha.6",
    "react-native": "0.43.3",
    "react-router-native": "^4.0.0",
    "react-router-redux": "^4.0.8",
    "redux": "^3.6.0"
  },
  "devDependencies": {
    "babel-jest": "19.0.0",
    "babel-preset-react-native": "1.9.1",
    "jest": "19.0.2",
    "react-test-renderer": "16.0.0-alpha.6"
  },
  "jest": {
    "preset": "react-native"
  }
}`}</code></pre></div>
    <p>{`Start by creating a dependencies.json file and move your dependencies object from the package.json file to the dependencies.json file`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`{
  "react-router-native": "^4.0.0",
  "react-router-redux": "^4.0.8",
  "redux": "^3.6.0"
}`}</code></pre></div>
    <blockquote>
      <p parentName="blockquote">{`Note: Sadly, react native templates don't support installing dev dependencies. See the conclusion for a workaround`}</p>
    </blockquote>
    <p>{`From here, it's time to strip out the entries in the package.json file that are no longer needed. Remove the dependencies, scripts, dev dependencies and anything else you don't need from the package.json. Finally, rename your template in the format `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`react-native-template-<your name here>`}</code>{`. For example, when a user specifies the "sample" template via `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`--template sample`}</code>{`, React Native will look for the npm package "react-native-template-sample" instead of the npm package called "sample"`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`{
  "name": "react-native-template-sample",
  "version": "0.0.1"
}`}</code></pre></div>
    <blockquote>
      <p parentName="blockquote">{`Note: Don't leave behind the dependencies block or anything unneeded in the package.json. React Native's CLI will ignore the package.json for the generated app and the additional installed depdendencies in your package.json can cause problems. In my case, React Native would install during the template creation and create extra native directories that would fail to compile.`}</p>
    </blockquote>
    <p>{`You also need to delete any directories containing native code that might interfere with your generated app.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`rm -rf ./android
rm -rf ./ios`}</code></pre></div>
    <p>{`Project templates also have a nifty feature so that if you put the string `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`HelloWorld`}</code>{` in your template anywhere, it'll get substituted with the project name supplied by the consumer of the template. The biggest place to watch out for this is in the index.ios.js and index.android.js where you register your app by name. Change the registration code to the following in both files:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`...your code here

AppRegistry.registerComponent('HelloWorld', () => App);`}</code></pre></div>
    <p>{`Be sure to also substitute anywhere else in your template that you use the app name with 'HelloWorld'`}</p>
    <h2>{`Publish`}</h2>
    <p>{`You can now test your template by running the following commands`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`# Initialize a new project pointing to the template you just made on your filesystem
react-native init test --template file://path/to/your/template
cd test
react-native run-android # or run-ios if that's your jam`}</code></pre></div>
    <p>{`If your app runs successfully, congratulations! It works!`}</p>
    <p>{`Now you have a template worthy of publishing. You can put it up on the npm registry for all to use and/or push it up to Github for others to see the source. It's totally up to you!`}</p>
    <h2>{`Conclusion`}</h2>
    <p>{`React Native projects typically start off with a lot of chores needed to get up and running. Now with the knowledge of React Native's project template feature you can remove that boilerplate and get to coding faster.`}</p>
    <h2>{`Notes`}</h2>
    <h3>{`Caveats with project templates`}</h3>
    <ul>
      <li parentName="ul">{`Dev Dependencies: basically have to write a script to initialize your dev dependencies yourself`}<ul parentName="li">
          <li parentName="ul">{`We tackled that by stealing a postInstall script from `}<a parentName="li" {...{
              "href": "https://github.com/doomsower/react-native-template-starter/blob/f6cd738d8352e53758ce8504f74464f8a407149e/scripts/postInit.js"
            }}>{`react-native-template-starter`}</a></li>
          <li parentName="ul">{`See `}<a parentName="li" {...{
              "href": "https://github.com/CodeKoalas/koality-react-native-template/blob/master/scripts/setupDevDependencies.js"
            }}>{`our version of the script`}</a>{` that can choose between yarn and npm and installs only dev dependencies for you `}</li>
        </ul></li>
      <li parentName="ul">{`Incompatibility with other CLI tools, namely haul and create-react-native-app`}</li>
      <li parentName="ul">{`Poor documentation, not sure if this will change or get better with time`}</li>
    </ul>
    <h3>{`Alternatives`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://infinite.red/ignite"
        }}>{`Ignite 2.0`}</a><ul parentName="li">
          <li parentName="ul">{`Ignite is a powerful CLI that gives you a lot of control out of the box for generating cool React Native apps`}</li>
          <li parentName="ul">{`If you tend to stick with the same stack or end up not needing a lot of Ignite's stuff, probably worth creating your own template`}</li>
          <li parentName="ul">{`If your favorite library isn't supported, have to still add it every time`}</li>
          <li parentName="ul">{`Note: You can simply take the generated Ignite app and strip it down into your own RN template`}</li>
        </ul></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://codecanyon.net/search?utf8=%E2%9C%93&term=react+native"
        }}>{`Code Canyon`}</a>{`, `}<a parentName="li" {...{
          "href": "https://strapmobile.com/"
        }}>{`Strap Mobile`}</a>{` or other online template stores`}<ul parentName="li">
          <li parentName="ul">{`Not OSS`}</li>
          <li parentName="ul">{`Unless your app matches their example closely, you're probably going to make major modifications`}</li>
          <li parentName="ul">{`Can't see what you're getting until you've already purchased it`}</li>
        </ul></li>
      <li parentName="ul">{`Hand rolled ala `}<a parentName="li" {...{
          "href": "http://yeoman.io/"
        }}>{`Yeoman`}</a>{` or Custom script`}<ul parentName="li">
          <li parentName="ul">{`You end up writing a lot more code to arrive at the same result`}</li>
          <li parentName="ul">{`A lot more error prone`}</li>
          <li parentName="ul">{`Infinitely more flexible than any of the options presented`}</li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      